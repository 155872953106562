<template>
	<b-row class="w-100 h-100">
		<b-col cols="4">
			<div class="img"></div>
		</b-col>
		<b-col class="d-flex align-items-end" cols="8">
			<div class="info">
				<div>
					<span>进入时间</span>
					<span>2022-02-22 09:10:32</span>
				</div>
				<div>
					<span>体温</span>
					<span>36.5 C</span>
				</div>
				<div>
					<span>黑名单</span>
					<span>否</span>
				</div>
			</div>
		</b-col>
		<b-col class="my-1 d-flex align-items-center" cols="12">
			<div style="width: 100%; height: 1px; background: #e4e4e4"></div>
		</b-col>
		<b-col cols="12">
			<h4>今日访客</h4>
			<b-table :current-page="currentPage" :fields="fields" :items="items" :per-page="perPage" small>
				<template v-slot:cell(image)="data">
					<b-avatar />
				</template>
			</b-table>
		</b-col>
	</b-row>
	<!--  <div class="w-100 h-100">-->
	<!--    <div class="top">-->
	<!--      <div class="tl">-->
	<!--        <div class="l_img"></div>-->
	<!--      </div>-->
	<!--      <div class="tr">-->
	<!--        <div class="r_info">-->
	<!--          <div class="info">-->
	<!--            <div>-->
	<!--              <span>进入时间</span>-->
	<!--              <span>2022-02-22 09:10:32</span>-->
	<!--            </div>-->
	<!--            <div>-->
	<!--              <span>体温</span>-->
	<!--              <span>36.5 C</span>-->
	<!--            </div>-->
	<!--            <div>-->
	<!--              <span>黑名单</span>-->
	<!--              <span>否</span>-->
	<!--            </div>-->
	<!--          </div>-->
	<!--        </div>-->
	<!--      </div>-->
	<!--    </div>-->
	<!--    <div class="bottom">-->
	<!--      <h4 class="py-1">今日访客</h4>-->
	<!--      <b-table-->
	<!--        :fields="fields"-->
	<!--        :items="items"-->
	<!--        :per-page="perPage"-->
	<!--        :current-page="currentPage"-->
	<!--        small-->
	<!--      >-->
	<!--        <template v-slot:cell(image)="data">-->
	<!--          <b-avatar />-->
	<!--        </template>-->
	<!--      </b-table>-->
	<!--    </div>-->
	<!--  </div>-->
</template>

<script>
	import { reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "index",
		setup() {
			const data = reactive({
				fields: [
					{ key: "image", label: "头像" },
					{ key: "access_time", label: "进入时间" },
					{ key: "leave_time", label: "设备名称" },
					{ key: "body_tmp", label: "体温" },
					{ key: "action", label: "操作" },
				],
				items: [
					{
						image: "",
						access_time: "",
						leave_time: "",
						body_tmp: "",
					},
					{
						image: "",
						access_time: "",
						leave_time: "",
						body_tmp: "",
					},
					{
						image: "",
						access_time: "",
						leave_time: "",
						body_tmp: "",
					},
					{
						image: "",
						access_time: "",
						leave_time: "",
						body_tmp: "",
					},
					{
						image: "",
						access_time: "",
						leave_time: "",
						body_tmp: "",
					},
				],
				perPage: 16,
				currentPage: 1,
			});
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style lang="scss" scoped>
	.img {
		width: 100%;
		height: 100%;
		background: #e4e4e4;
	}

	.info {
		width: 30%;

		div {
			display: flex;
			justify-content: space-between;
		}
	}

	//.top {
	//  height: 40%;
	//  display: flex;
	//  border-bottom: 1px solid #ebebeb;
	//  .tl {
	//    flex: 3;
	//    margin-right: 2rem;
	//    .l_img {
	//      width: 100%;
	//      height: 90%;
	//      background: #00ff80;
	//    }
	//  }
	//  .tr {
	//    flex: 7;
	//    .r_info {
	//      width: 100%;
	//      height: 90%;
	//      display: flex;
	//      align-items: end;
	//      .info {
	//        width: 20%;
	//        div {
	//          display: flex;
	//          justify-content: space-between;
	//          padding-top: 1rem;
	//        }
	//      }
	//    }
	//  }
	//}
	//.bottom {
	//  height: 60%;
	//}
</style>
